import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { css } from "styled-components";

import { Flex, Box, Image, Heading, Text } from "../../globals/UIKit";
import { StyledContainerWrapper } from "../shared/StyledContainerWrapper";
import { isBrowser } from "../../utils/constants";
import Paperform from "../contactus/Paperform";

const TermsForm = props => {
  const [isLocalHasuraAgreeConsent, setisLocalHasuraAgreeConsent] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const hasuraAgreeConsent = window.localStorage.getItem("hasuraAgreeConsent");

        if (hasuraAgreeConsent) {
          setisLocalHasuraAgreeConsent(true);
        }
      }
    }
  }, [props.location.search]);

  const onSubmitCB = () => {
    if (isBrowser) {
      window.localStorage.setItem("hasuraAgreeConsent", "true");
    }
  };

  if (isLocalHasuraAgreeConsent) {
    return (
      <Flex
        bg="#fff"
        borderRadius="8px"
        css={css`
          max-height: 500px;
          box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          padding-bottom: 40px;
          padding: 0px 72px;

          @media (max-width: 600px) {
            padding: 30px;
            max-height: 300px;
          }
        `}
        height="505px"
        width="100%"
        maxWidth="490px"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Text
          color="#111927"
          fontSize="32px"
          fontWeight="700"
          lineHeight="1.25"
          letterSpacing="-0.015em"
          mb="16px"
          className="form-head"
        >
          Thank you for Registering!
        </Text>
        <Text
          color="#111927"
          fontSize="20px"
          fontWeight="400"
          lineHeight="1.25"
          letterSpacing="-0.01em"
        >
          {`Our team will be in touch with you shortly! We hope you enjoy the Hasura experience :)`}
        </Text>
      </Flex>
    );
  }

  return (
    <Box
      bg="#fff"
      borderRadius="8px"
      boxShadow="0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
      // padding="40px 40px 20px"
      padding="40px"
      width="100%"
      maxWidth="490px"
      className="form-div-mobile"
      // css={css`
      //   @media (min-width: 1024px) {
      //     min-height: 500px;
      //   }
      // `}
    >
      <Text
        color="#111927"
        fontSize="24px"
        fontWeight="700"
        lineHeight="1.4"
        letterSpacing="-0.01em"
        zIndex="1"
        position="relative"
        // pl="25px"
        // mb="55px"
        className="form-head"
      >
        Register your Hasura Product
      </Text>
      <Box width="100%" mt="-5px">
        <Paperform formId="hf-accept-terms-of-service" onSubmitCB={onSubmitCB} styleClass="" />
      </Box>
      <Text
        fontSize="14px"
        color="#111927;"
        fontWeight="400"
        lineHeight="1.5"
        css={css`
          a {
            color: #1e56e3;
          }
        `}
      >
        By registering, you agree to our{" "}
        <Link to="/legal/msa/">
          Master Software and Services License Agreement
        </Link>
      </Text>
    </Box>
  );
};

export const TopBanner = props => (
  <Box
    css={css`
      padding: 113px 0 170px;
      background: linear-gradient(0deg, #ffffff 7.09%, #cfe8ff 100%);

      @media (max-width: 918px) {
        padding: 60px 0;
      }

      @media (max-width: 390px) {
        .form-head {
          font-size: 16px;
        }
      }

      @media (max-width: 600px) {
        .form-head {
          font-size: 20px;
        }

        .form-div-mobile {
          margin-left: -12px;
          margin-right: -12px;
          padding: 36px 24px;
        }

        .logo-div {
          img {
            margin: 0 6px;
          }
        }
      }

      @media (max-width: 350px) {
        .logo-div {
          margin-left: -12px;
          margin-right: -12px;
        }
      }
    `}
  >
    <StyledContainerWrapper>
      <Flex
        width="100%"
        alignItems="flex-start"
        pl="46px"
        justifyContent="space-between"
        css={css`
          @media (max-width: 1100px) {
            padding-left: 0;

            .logo-div {
              width: 100%;
              img {
                margin: 0 12px;
              }
            }
          }

          @media (max-width: 918px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .left-content-div {
              h1,
              p {
                max-width: 100%;
                text-align: center;
              }

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 55px;
            }
          }
        `}
      >
        <Box className="left-content-div">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676619509/main-web/Artboard_4_l6t6yw.png"
            alt="built-with-hasura-img"
            width="160px"
            ml="-19px"
            mb="18px"
          />
          <Heading
            color="#1F2A37"
            fontSize="32px"
            fontWeight="700"
            lineHeight="1.3"
            maxWidth="320px"
            letterSpacing="-0.015em"
            mb="8px"
          >
            Thanks for choosing to build with Hasura
          </Heading>
          <Text
            color="#111927"
            fontSize="20px"
            fontWeight="400"
            lineHeight="1.6"
            maxWidth="330px"
            letterSpacing="-0.01em"
            mb="55px"
          >
            The simplest way to build your data API platform and supercharge your application
            development.
          </Text>
          <Text
            color="#9DA4AE"
            fontSize="12.17px"
            fontWeight="700"
            lineHeight="1.6"
            maxWidth="330px"
            letterSpacing="0.0005em"
            mb="21px"
          >
            TRUSTED BY GLOBAL BRANDS
          </Text>
          <Flex width="415px" maxWidth="415px" justifyContent="space-between" className="logo-div">
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676620857/main-web/icons/Group_10630_ejzlod.png"
              alt="swiggy-icon"
              width="72px"
              height="40.82px"
            />
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676620857/main-web/icons/Group_10631_lywnef.png"
              alt="netlify-icon"
              width="70.6px"
              height="37.7px"
            />
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676620857/main-web/icons/Group_10626_na66s1.png"
              alt="walmart-icon"
              width="78px"
              height="37.69px"
            />
          </Flex>
          <Flex
            width="415px"
            maxWidth="415px"
            justifyContent="space-between"
            mt="15px"
            className="logo-div"
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676620857/main-web/icons/Group_10629_vqshk9.png"
              alt="airbus-icon"
              width="60px"
              height="39px"
            />
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676620857/main-web/icons/Group_10627_hjzotv.png"
              alt="virginia-icon"
              width="52.23px"
              height="37.6px"
            />
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1676620857/main-web/icons/Group_10629_1_jdhwul.png"
              alt="atlassian-icon"
              width="82px"
              height="35.78px"
            />
          </Flex>
        </Box>
        {/* Form */}
        <TermsForm location={props.location} />
      </Flex>
    </StyledContainerWrapper>
  </Box>
);
