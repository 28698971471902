import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { TopBanner } from "../components/TermsOfService";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/card.png",
};

const canonicalUrl = "https://hasura.io/accept-terms-of-service/";

const TermOfService = props => (
  <Layout location={props.location}>
    <Seo
      title="Thanks for choosing to build with Hasura"
      description="Hasura | The simplest way to build your data API platform and supercharge your application development."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner location={props.location} />
  </Layout>
);

export default TermOfService;
